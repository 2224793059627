<template>
  <main class="kb-main" id="kb-helpdesk">
    <lxp-main-header :show-breadcrumb="true" :show-title="true" title="Q&A">
      <template v-slot:action>
        <div class="header-dropdown">
          <div class="dropdown" :class="{ 'is-active' : isCategoryActive }"
               @click="isCategoryActive = !isCategoryActive">
            <button class="dropdown-btn kb-btn kb-btn-outline"><strong class="text">{{search.inqTyNm}}</strong><i class="icon-arrow"></i>
            </button>
            <div class="dropdown-target">
              <div class="dropdown-box">
                <ul class="dropdown-option-list">
                  <li class="dropdown-option-item" v-for="(item, idx) in inqTyCdDcds" :key="idx">
                    <a href="javascript:" @click="setInqCate(item)" class="dropdown-option-link"><strong class="dropdown-option-text">{{item.cdNm}}</strong></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>

    <div class="main-content min-component">
      <div class="qna-list-container">
        <div style="text-align: right;"><button class="kb-btn kb-btn-outline rounded-lg" @click="qnaWrite">글쓰기</button></div>
        <div class="list-top">
          <div class="top-column ps-6">
            <p class="title text-muted">총 {{paging.totalCount}}건</p>
          </div>
          <div class="top-column">
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" placeholder="Q&amp;A 검색"
                       v-model="search.inqTitle"
                       @keyup.enter="clickSearch"
                >
                <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="board-list-container">
          <ul class="board-list">
            <li class="board-list-item" v-for="(item, idx) in items" :key="idx">
              <article class="board-row">
                <router-link :to="{path:'/help/qna/'+item.comInqSn}" class="board-link"></router-link>
                <div class="board-column column-index"><em class="text">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</em></div>
                <div class="board-column column-category"><span class="text">{{item.inqTyNm}}</span></div>
                <div class="board-column column-status"><span class="text">{{item.ansYn === 'Y' ? '답변완료' : '처리중'}}</span></div>
                <div class="board-column column-title"><span class="title">{{item.inqTitle}}</span><i v-if="item.newNotice === 'Y'" class="icon-new"></i></div>
                <div class="board-column column-writer"><span class="text">{{item.lrnerNm}}</span></div>
                <div class="board-column column-date"><span class="text">{{getDateFormat('yyyy.MM.dd hh:mm', item.regDt)}}</span></div>
              </article>
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getItems, getPaging, initPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {reactive, watch, ref, computed, onMounted} from "vue";
import {ACT_GET_HELP_QNA_LIST} from "@/store/modules/help/help";
import CommonPaginationFront from "@/components/CommonPaginationFront";
export default {
  name: 'HelpQna',
  components: {CommonPaginationFront, LxpMainHeader},
  setup() {
    const isCategoryActive = ref(false);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([{}]);
    const search = reactive({
      inqTitle:'',
      inqTyCdDcd: '',
      inqTyNm: '카테고리'
    })

    const inqTyCdDcds = computed(() =>
        store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2091') === 0 && code.cd.indexOf('209101') !== 0 ));
    inqTyCdDcds.value.unshift({cd: '', cdNm: '카테고리'})

    onMounted(() => {
      getHelpQnaList();
    })

    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));
    const getHelpQnaList = () => {
      store.dispatch(`help/${ACT_GET_HELP_QNA_LIST}`, {
        inqTitle: search.inqTitle,
        inqTyCdDcd: search.inqTyCdDcd,
        stt: '00',
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
          // 새 게시물인지 날짜로 비교
          items.value.map((x) => {
            let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
            let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
            let date = new Date(diff).getDate();
            if(month + date <= 7) x.newNotice = 'Y';
            else x.newNotice = 'N';
          })
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.inqTitle) query.inqTitle = search.inqTitle;
      if(search.inqTyCdDcd) query.inqTyCdDcd = search.inqTyCdDcd;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    watch(() => route.query, () => {
      if (route.name === 'HelpQna') {
        if (route.query.pageNo) {
          paging.value.pageNo = route.query.pageNo;
        } else {
          paging.value.pageNo = 1;
        }
        if (route.query.pageSize) {
          paging.value.pageSize = route.query.pageSize;
        }
        getHelpQnaList();
      }
    });

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    }

    const setInqCate = (item) => {
      search.inqTyCdDcd = item.cd;
      search.inqTyNm = item.cdNm;
      pagingFunc(1, 10);
    }

    const qnaWrite = () => {
      router.push({name: 'HelpQnaWrite'});
    }

    return {
      isCategoryActive,
      items,
      paging,
      search,
      inqTyCdDcds,
      pagingFunc,
      clickSearch,
      getDateFormat,
      qnaWrite,
      setInqCate,
    };
  },
};
</script>

<style scoped>

</style>